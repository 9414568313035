<template>
	<section>
		<div class="container-fluid">
			<div class="col-md-12">
				<div class="alt-2"></div>
				<div class="vx-row">
					<div class="vx-col w-full">
						<h4>Consentimiento Inversión Automática (Recursos Disponibles)</h4>
						<p class="mt-5">
							Al dar clic en Autorizar aceptarás que RedGirasol invierta de manera automática de acuerdo a
							los <b>términos y condiciones</b> de la <b>"Inversión Automática"</b>, los <b>recursos disponibles</b> que se
							encuentren en tu cuenta, al momento que actives la Inversión Automática. Los recursos
							disponibles pueden ser:
						</p>
						<p class="mt-5">
							<div class="text-center bold">Pagos efectuados por los financiamientos otorgados a los Solicitantes</div>
							Los recursos que provienen de pagos de principal , accesorios e impuestos realizados por los
							Solicitantes en cumplimiento a los términos y condiciones de los préstamos o créditos
							otorgados.
						</p>
						<p class="mt-5">
							<div class="text-center bold">Operaciones no perfeccionadas</div>
							Lo recursos que provienen de Operaciones no celebradas con los Solicitantes de
							financiamiento colectivo en el “Plazo de Solicitud de Financiamiento Colectivo” en virtud de
							que no se reunieron los fondos/recursos del préstamo o crédito y el solicitante determinó
							cancelar su solicitud antes de la celebración de los contratos y a los retiros no procesados
							a las cuentas receptoras de recursos dinerarios que los Clientes de la institución de
							financiamiento colectivo registren en la Plataforma.<br><br>
							Por este medio, <strong><u>{{userName}}</u></strong> en mi calidad de Comitente del Contrato de Comisión
							Mercantil, autorizo a RED GIRASOL SAPI DE CV INSTITUCIÓN DE FINANCIAMIENTO COLECTIVO para
							que en mi nombre pueda invertir de forma automática los recursos disponibles de <b>Pagos
							efectuados por los financiamientos otorgados a los Solicitantes y Operaciones no
							perfeccionadas.</b>
						</p>
						<p class="mt-5">
							<div class="text-center bold">Otros recursos</div>
							Los recursos que mantenga en mi cuenta que no provenga de <b>Pagos efectuados por los
							financiamientos otorgados a los Solicitantes y Operaciones no perfeccionadas</b>, incluyendo mas
							no limitando los provenientes de abonos o depósitos recibidos en la Plataforma por el
							Inversionista, abonos de recuperaciones, bonos de promociones, etc.<br><br>
							Por este medio, <strong><u>{{ userName }}</u></strong> en mi calidad de Comitente del Contrato de Comisión
							Mercantil, autorizo a RED GIRASOL SAPI DE CV INSTITUCIÓN DE FINANCIAMIENTO COLECTIVO para
							que en mi nombre pueda invertir de forma automática los recursos disponibles que mantenga en
							mi cuenta.
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import investorInfoHelper from "@/helpers/investorInfoHelper";

export default {
	name: "AutomaticInvestmentConsentV2",
	mixins: [investorInfoHelper],
	props: {
		fillUserData: {
			type: Boolean
			, default: false
		},
	},
	data() {
		return {
		};
	},
	computed: {
		userName: function () {
			if (this.fillUserData && this.user) {
				if (!this.userIsMoral(this.user.user_person_type)) {
					return this.user.complete_name;
				} else {
					return this.user.legal_representative_name;
				}
			} else {
				return "";
			}
		},
	},
	methods: {
	},
};
</script>